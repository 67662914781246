import React, {useMemo, useState} from 'react';
import {
  Box, Button,
  Grid2 as Grid,
  Modal,
  Step,
  Stepper,
  Typography,
} from "@mui/material";
import {
  MuiActionButton,
  MuiCancelActionButton,
  MuiStepLabel,
  MuiStepLabelTypography,
  SurveyConnector,
} from 'components/views/organizations/surveys/styles/container.js'
import GeneralInformation from './steps/GeneralInformation';
import {useTranslation} from 'components/providers/TranslationProvider';
import FlowDesign from "./steps/FlowDesign";
import Translations from './steps/Translations';
import {useLocation, useNavigate} from 'react-router-dom';
import {useLoading} from "../../../providers/LoadingProvider";
import {useCreateSurvey} from "../../../../api/hooks/useCreateSurvey";
import useSurvey from "../../../../api/hooks/useSurvey";
import englishFlag from "../../../../assets/img/english.png";
import {
  useProvisionDefaultCommunicationInterface,
  useProvisionNetworkingInfrastructure,
  useProvisionResponseProcessors
} from "../../../../api/hooks/useSurveyInfrastructure";
import useStartSurveyService from "../../../../api/hooks/useStartSurveyService";
import CommunicationChannels from "./steps/CommunicationChannels";
import AgentBehavior from './steps/AgentBehavior';
import WhatsappTemplate from './steps/WhatsappTemplate';
import {MuiAcceptButton} from "../scenarios/exporting/styles/viewReportPDF";
import {
  MuiNextActionButton
} from "../datasets/creation/styles/datasetUsageConfirmation";
import {usePublishSurvey} from "../../../../api/hooks/usePublishSurvey";

const buildNewSurveyInstance = () => ({
  // id: '',
  name: '',
  defaultLanguage: "english",
  additionalLanguages: [],
  region: "US",
  profileImageUrl: ''
});

const Container = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [defaultLanguage, setDefaultLanguage] = React.useState({
    name: "english",
    flag: englishFlag
  });

  const location = useLocation();
  const navigate = useNavigate();
  const isCreating = location.pathname.split('/')[4] === 'create'
  const orgId = location.pathname.split('/')[2]
  const [survey, setSurvey] = useState(isCreating ? buildNewSurveyInstance() : {});
  const [surveyId, setSurveyId] = React.useState(location.pathname.split('/')[4]);

  const {t} = useTranslation();
  const {
    setIsLoading,
    setTitle,
    setProgress,
    setProgressMessage,
    setShowProgressBar,
    setIsClassificationLoading
  } = useLoading();
  const {mutateAsync: createSurvey} = useCreateSurvey();
  const {mutateAsync: provisionNetworkInfrastructure} = useProvisionNetworkingInfrastructure();
  const {mutateAsync: provisionResponseProcessors} = useProvisionResponseProcessors();
  const {mutateAsync: provisionDefaultCommunicationInterface} = useProvisionDefaultCommunicationInterface();
  const {mutateAsync: startSurveyService} = useStartSurveyService();
  const {data: pathSurvey} = useSurvey({id: surveyId});
  const [openPublish, setOpenPublish] = React.useState(false);
  const {mutateAsync: publish} = usePublishSurvey({service_id: survey?.serviceId});
  console.log(survey)
  const isLastStep = useMemo(() => {
    return activeStep === 5;
  }, [activeStep]);

  const isFirstStep = useMemo(() => {
    return activeStep === 0;
  }, [activeStep]);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleCreateInterview = async () => {
    let createdSurvey;

    setIsLoading(true);
    setTitle(isCreating ? 'Creating your interview' : 'Loading your interview');
    setShowProgressBar(true);
    setIsClassificationLoading(true);
    setProgress(0);
    const newSurvey = {
      survey_name: survey.name,
      organization_id: orgId,
      region_name: survey.dataRegion,
      main_language: survey.defaultLanguage,
      profileImageUrl: survey.profileImageUrl,
      additional_languages: survey.additionalLanguages.filter(l => l.name !== survey.defaultLanguage).map(l => l.name),
      environment: 'dev',
    };

    try {
      createdSurvey = await createSurvey(newSurvey)
      if (createdSurvey) {
        await provisionInfrastructure(createdSurvey);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
      setTitle('');
      setProgress(0);
      setProgressMessage('');
      setShowProgressBar(false);
      setIsClassificationLoading(false);
      setSurveyId(createdSurvey._id)
      setActiveStep(1);
    }
  };

  const provisionInfrastructure = async (surveyDef) => {
    setProgress(25);
    setTitle('Provisioning the survey infrastructure');
    await new Promise(resolve => setTimeout(resolve, 500));

    if (!surveyDef.loadBalancer || !surveyDef.targetGroupArn || !surveyDef.engineBaseUrl) {
      setProgressMessage("Configuring the communication servers");
      await provisionNetworkInfrastructure({
        serviceId: surveyDef.serviceId,
        environment: surveyDef.environment
      });
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    setProgress(50);
    if (!surveyDef.responseProcessorArn || !surveyDef.audioProcessorArn) {
      setProgressMessage("Initializing the interview agents");
      await provisionResponseProcessors({
        serviceId: surveyDef.serviceId,
        environment: surveyDef.environment
      });
    }
    setProgress(75);
    setProgressMessage("Initializing the communication interface");
    await new Promise(resolve => setTimeout(resolve, 500));
    if (!surveyDef.clusterArn || !surveyDef.serviceArn || !surveyDef.taskDefinitionArn) {
      await provisionDefaultCommunicationInterface({
        serviceId: surveyDef.serviceId,
        environment: surveyDef.environment
      });
    } else {
      console.log("Starting the survey service", surveyDef);
      await startSurveyService({
        serviceId: surveyDef.serviceId,
        environment: surveyDef.environment
      });
    }
    await new Promise(resolve => setTimeout(resolve, 500));
    setProgress(100);
    setProgressMessage("Everything is ready!");
    await new Promise(resolve => setTimeout(resolve, 2000))
  }

  React.useEffect(() => {
    if (pathSurvey) {
      setSurvey(pathSurvey)
    }
  }, [pathSurvey]);
  const goToSurveys = () => {
    setOpenPublish(true);
  }
  const publishInterview = async () => {
    await publish(survey?.serviceId);
    setOpenPublish(false);
    navigate(`/orgs/${orgId}/surveys`);
  }
  const handleClose = () => {
    setOpenPublish(false);
    navigate(`/orgs/${orgId}/surveys`);
  }

  return <Grid container direction={'column'}
               id={'config-survey-container'}>
    <Grid item xs={12} sx={{marginTop: '13px'}}>
      <Stepper activeStep={activeStep} orientation='horizontal'
               sx={{width: '100%', height: '46px'}}
               connector={<SurveyConnector/>}>
        <Step key={'general-step'} sx={{pl: "0px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('general')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
        <Step key={'flow-design-step'} sx={{pl: "12px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('flow_design')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
        <Step key={'translations-step'} sx={{pl: "12px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('survey_translations')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
        <Step key={'agent-behavior-step'} sx={{pl: "12px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('agent_behavior')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
        <Step key={'communication-settings-step'} sx={{pl: "12px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('communication_settings')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
        <Step key={'wapp-templates-step'} sx={{pl: "12px"}}>
          <MuiStepLabel>
            <MuiStepLabelTypography activeStep={activeStep}>
              {t('whatsapp_templates')}
            </MuiStepLabelTypography>
          </MuiStepLabel>
        </Step>
      </Stepper>
    </Grid>
    {activeStep === 0 && (
      <GeneralInformation
        survey={survey}
        setSurvey={setSurvey}
        defaultLanguage={defaultLanguage}
        setDefaultLanguage={setDefaultLanguage}
        isEditing={!isCreating}
      />
    )}
    {activeStep === 1 && (
      <FlowDesign
        survey={survey}
        setSurvey={setSurvey}
        defaultLanguage={defaultLanguage}
      />
    )}
    {activeStep === 2 && (
      <Translations survey={survey} setSurvey={setSurvey}/>
    )}
    {activeStep === 3 && (
      <AgentBehavior survey={survey} setSurvey={setSurvey}/>
    )}
    {activeStep === 4 && (
      <CommunicationChannels
        survey={survey}
        setSurvey={setSurvey}
      />
    )}
    {activeStep === 5 && (
      <WhatsappTemplate
        survey={survey}
        setSurvey={setSurvey}
        templateId={survey?.templateId}
      />
    )}

    <Grid item xs={12} sx={{width: '100%'}}
    >
      <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        marginTop: '34px'
      }}>
        <MuiCancelActionButton
          onClick={()=>  navigate(`/orgs/${orgId}/surveys`)}
        >
          {t('cancel_generic_btn')}
        </MuiCancelActionButton>
        {(activeStep !== 0) && (
          <MuiActionButton
            onClick={handleBack}
          >
            {t('go_back')}
          </MuiActionButton>
        )
        }

        <MuiActionButton
          sx={{padding: '10px 24px', borderRadius: '20px'}}
          onClick={isFirstStep ? handleCreateInterview : isLastStep ? goToSurveys : handleNext}
          variant='outlined'
        >
          {isFirstStep && isCreating ? t('create_interview') : isLastStep ? t('finish') : t('next_step')}
        </MuiActionButton>
      </Box>
    </Grid>
    <Modal
      open={openPublish}
      onClose={handleClose}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 600,
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '28px',
        padding: '24px'
      }}>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            fontSize: '21px',
            color: '#212121'
        }}>
          {t('publish_interview')}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '16px',
            color: '#616161'
          }}>
          {t('publish_interview_info')}
        </Typography>
        <Box sx={{display:'flex', gap: '6px', alignItems: 'center', justifyContent: 'flex-end', mt: '20px'}}>
          <Button onClick={publishInterview}>YES</Button>
          <Button onClick={handleClose}>NO</Button>
        </Box>
      </Box>
    </Modal>
  </Grid>

};

export default Container;
