import {
  Box,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {lighten} from "@mui/material/styles";
import {PlayArrow} from "@mui/icons-material";
import {ReactFlow, useNodesState} from "@xyflow/react";
import '@xyflow/react/dist/style.css';
import '../styles/nodesStyles.css';
import CustomNode from "../CustomNode";
import Messages from "./Messages";
import React, {useEffect, useRef, useState} from 'react';
import QRModal from "../QRModal";
import useGetQuestionsGraph from "../../../../../api/hooks/useQuestionsGraph";
import {useLoading} from "../../../../providers/LoadingProvider";
import useSurveyQuestions from "../../../../../api/hooks/useSurveyQuestions";
import useManageQuestion from "../../../../../api/hooks/useCreateQuestion";

const FlowDesign = ({survey, setSurvey, defaultLanguage}) => {
  const [showModal, setShowModal] = useState(false);
  const [showGenericModal, setShowGenericModal] = useState(false);
  const [nodeSelected, setNodeSelected] = useState(null);
  const [modalQR, setModalQR] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage || {});
  const {
    isLoading: isLoadingGraph,
    data: graph,
    refetch: refetchGraph
  } = useGetQuestionsGraph({serviceId: survey.serviceId, language: selectedLanguage?.name});

  const {
    isLoading: isLoadingQuestions,
    data: questionsList,
    refetch: refetchQuestions
  } = useSurveyQuestions(survey);
  const {setIsLoading, setTitle} = useLoading();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [questions, setQuestions] = useState([]);
  const questionsMap = useRef(new Map());
  const [nodesFlow, setNodesFlow] = useNodesState(nodes);
  const [listLanguagesInterview, setListLanguagesInterview] = React.useState([defaultLanguage])

  const {deleteQuestion} = useManageQuestion();
  const {t} = useTranslation();
  const {theme} = useTheme();

  const setShowModalMessage = (data) => {
    if (data.type !== 'addNode') {
      setShowGenericModal(true);
    }
    setShowModal(true);
    setNodeSelected(data);
  };

  React.useEffect(() => {
    if(isLoadingGraph || isLoadingQuestions || !graph || !questionsList) return;
    setIsLoading(true);
    setTitle("Getting your flow data...");
    if (graph && Object.keys(graph).length > 0) {
      questionsMap.current = new Map();
      setEdges(graph.edges);
      setNodes(graph.nodes);
    }
    if (questionsList && Object.keys(questionsList).length > 0) {
      setQuestions(questionsList);
    }
    setIsLoading(false);
  }, [graph, isLoadingGraph, isLoadingQuestions, questionsList]);

  const selectedLangObj =
    listLanguagesInterview.find((lang) => lang.name === selectedLanguage.name)
    || selectedLanguage.name;

  const handleChange = (event) => setSelectedLanguage(event.target.value);

  const deleteQuestionExecute = async (id) => {
    const data = {id, service_id: survey.serviceId, environment: survey.environment};
    const respDelete = await deleteQuestion(data);
    if (respDelete){
      questionsMap.current = new Map();
      const respQuestions = await refetchGraph();
      if (respQuestions?.status === "success") {
        questionsMap.current = new Map();
        await refetchQuestions();
      }
    }
  }

  const nodeTypes = { customNode: (props) =>
      <CustomNode {...props}
                  setShowModal={setShowModal}
                  setShowModalMessage={setShowModalMessage}
                  deleteQuestion={deleteQuestionExecute}
      /> };

  const  handleCloseModal = async () => {
    setShowModal(false);
    setShowGenericModal(false);
    setNodeSelected(null);
  }

  const  refrechDataGraph = async () => {
    const reloadGraph = await refetchGraph();
    if (reloadGraph?.status === "success") {
      await refetchQuestions();
    }
  }

  const getInternationalization = (id, language) => {
    const question = questions.find(item => item.id === id);
    if (!question) return null;

    const internationalization = question?.internationalization.find(item => item.language === language);
    return internationalization ? internationalization : null;
  };

  const updateNodesLanguage = (ids) => {
    setNodesFlow(prevNodes => {
      let updatedNodes = [...prevNodes];

      ids.forEach(id => {
        let instanceInternationalization = getInternationalization(id, selectedLangObj.name);
        if (!instanceInternationalization) return;

        let labels = instanceInternationalization.allowedAnswers
        let mainText = instanceInternationalization.text
        let labelIndex = 0;
        let translationOptions = [];

        updatedNodes = updatedNodes.map(node => {
          if (node.data?.sourceEntityId === id) {
            translationOptions.push(labels[labelIndex]);
            labelIndex++;
            return {
              ...node,
              data: {
                ...node.data,
                label: labels[labelIndex - 1]?.answer || node.data.label,
              }
            };
          }
          return node;
        });

        updatedNodes = updatedNodes.map(node => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                mainText: mainText || node.data.mainText,
                internationalization: instanceInternationalization || node.data.internationalization,
                options: translationOptions.length > 0 ? translationOptions : node.data.options || [],
              }
            };
          }
          return node;
        });
      });

      return updatedNodes;
    });
  };

  useEffect(() => {
    if (nodesFlow.length > 0) {
      const ids = nodesFlow.map(node => node.id);
      updateNodesLanguage(ids);
    }
  }, [selectedLanguage, nodesFlow.length]);
  const findAnswerId = (data, id, language, answer) => {
    const item = data.find(obj => obj.id === id);
    if (!item) return null;

    const intl = item.internationalization.find(intl => intl.language === language);
    if (!intl) return null;

    const ans = intl.allowedAnswers.find(ans => ans.answer === answer);
    return ans ? ans.id : null;
  };

  useEffect(() => {
    if (graph && Object.keys(graph).length > 0 && questions && questions.length > 0) {
      const baseX = 0;
      const baseY = 0;
      const offsetY = 100;
      const offsetX = 200;
      const sources = new Set(edges.map(edge => edge.source));
      nodes.forEach((node, index) => {
        const position = (node.data.x !== 0 || node.data.y !== 0) ? node.position : {x: baseX, y: baseY + index * 50}
        const question = questions.find(item => item.id === node.id);
        const profileImage = question?.internationalization.find((item) => item.language === selectedLangObj.name)?.headerImageUrl || "";
        const footerText = question?.internationalization.find((item) => item.language === selectedLangObj.name)?.footerText || "";
        const title = question?.internationalization.find((item) => item.language === selectedLangObj.name)?.title || "";
        const mainText = question?.internationalization.find((item) => item.language === selectedLangObj.name)?.text || "";
        const internationalization = question?.internationalization;
        const preconditions = question?.preconditions || [];
        if (node.type === "question" || node.type === "follow-up") {
          questionsMap.current.set(node.id, {
            id: node.id,
            type: "customNode",
            position: position,
            data: {
              label: node.data.questionType === 'CONFIRMATION' ? 'WHATSAPP CARD' :
                 node.data.questionType === 'CHOICE' ? 'OPTION SELECTION' :
                 node.data.questionType === 'OPEN' ?
                 node.data.type === 'follow-up' ? 'FOLLOW-UP' : 'OPEN-ENDED' : '',
              entity: node.data.entity,
              type: node.data.questionType === 'CONFIRMATION' ? 'start' : node.data.questionType === 'CHOICE' ? 'multipleChoice' : node.data.questionType === 'OPEN' ?  node.data.type === 'follow-up' ? 'followUp' : 'openEnded' : node.data.questionType,
              text: node.data.label || "",
              title: title,
              footerText: footerText,
              mainText: node.data.mainText || mainText,
              profileImageUrl: profileImage,
              options: [],
              id: node.id,
              preconditions: preconditions,
              x: position.x,
              y: position.y,
              sourceId: question?.type === 'OPEN' ? question?.parentQuestion ? question?.parentQuestion.id : question.id : null,
              internationalization: node.data.internationalization || internationalization
        }
      });
    } else if (node.data.type === 'message') {
      const position = {x: 300, y: baseY + 50 + index * 50};
      questionsMap.current.set(node.id, {
        id: node.id,
        type: "customNode",
        position: position,
        data: {
          label: 'MESSAGE',
          entity: node.data.type,
          type: node.data.type,
          text: node.data.label || "",
          mainText: node.data.text || "",
          options: []
            }
          });
        }
        if (!sources.has(node.id)) {
          if (node.data.type !== 'message') {
            questionsMap.current.set(`new-${node.id}`, {
              id: `new-${node.id}`,
              sourceId: node.id,
              type: "customNode",
              draggable: true,
              position: {x: 0, y: 0},
              data: {
                label: "add",
                type: "addNode"
              }
            });
            setEdges(prevEdges => [
              ...prevEdges,
              {
                id: `edge-${node.id}-new-${node.id}`,
                source: node.id,
                target: `new-${node.id}`,
                style: { strokeDasharray: '5,5', stroke: '#E0E0E0' }
              }
            ]);
          }
        }
      });
      nodes.forEach(node => {
        if (node.type === "question-answer") {
          const parentNode = questionsMap.current.get(node.data.sourceEntityId);
          if (parentNode) {
            const children = nodes.filter(n => n.data.sourceEntityId === parentNode.id);
            const totalChildren = children.length;
            const startX = parentNode.position.x - ((totalChildren-1) * offsetX - 50) / 2;
            const yPosition = parentNode.position.y + offsetY;
            children.forEach((childNode, index) => {
              const xPosition = startX + index * offsetX;
              const updatedChildNode = {
                ...childNode,
                position: { x: xPosition, y: yPosition }
              };
              questionsMap.current.set(childNode.id, updatedChildNode);
              const exists = parentNode.data.options.some(option => option.answer === childNode.data.label);
              if(!exists){
                parentNode.data.options.push({id : String(index) , answer: childNode.data.label});
              }
            });
          }
        }
      });
      let nodesTest = Array.from(questionsMap.current.values());
      nodesTest = nodesTest.map(node => {
        if (node.data.type === "addNode") {
          const parentNode = nodesTest.find(n => n.id === node.sourceId);
          const xPosition = parentNode.data.type === 'openEnded' || parentNode.data.type === 'followUp' ? parentNode.position.x + 87 : parentNode.position.x + 62;
          const yPosition = parentNode.data.type === 'openEnded' || parentNode.data.type === 'followUp' ? parentNode.position.y + 120 : parentNode.position.y + 70;
          const parentId =  parentNode.data.type === 'openEnded' || parentNode.data.type === 'followUp' ? parentNode.data.id : parentNode.data.sourceEntityId ;
          return {
            ...node,
            position: {x: xPosition, y: yPosition},
            data: {
              ...node.data,
              label: "add",
              type: "addNode",
              position: {x: xPosition, y: yPosition},
              sourceId: parentId ,
              option: findAnswerId(questions,parentId , selectedLangObj.name, parentNode.data.label),
            }
          };
        }
        return node;
      });
      setNodesFlow(nodesTest)
    }
  }, [graph, questionsList, nodes, edges]);

  const testInterview = survey && survey.status==='Running';

  React.useEffect(() => {
    setListLanguagesInterview(prevLanguages => [...prevLanguages, ...survey.additionalLanguages]);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          borderRadius: "20px",
          backgroundColor: "#FAFAFA",
          minHeight: "600px",
          padding: "24px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Typography variant="h6" sx={{ fontFamily: "Raleway" }}>
            {t('interview_flow')}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography
              variant="overline"
              sx={{
                fontFamily: "Montserrat",
                color: lighten(theme.palette.primary.main, 0.1),
              }}
            >
              {t('see_interview')}
            </Typography>
            <FormControl >
              <Select
                value={selectedLanguage}
                onChange={handleChange}
                sx={{
                  height: 26,
                  width:128,
                  borderRadius: 13,
                  backgroundColor: "transparent",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 13,
                    borderColor: theme.palette.primary.main,
                  },
                  "& .MuiSvgIcon-root": {
                   color: theme.palette.primary.main,
                  },
                  "& .css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding:'4px 5px 4px 4px !important',
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                  }
                }}
                displayEmpty renderValue={() => (
                selectedLangObj && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt={''}
                      src={selectedLangObj.flag}
                      style={{
                        height: 18,
                        width: 18,
                        marginRight: 10,
                        marginTop: 1
                      }}
                    />
                    <Typography
                      variant='body2'
                      style={{
                        color: '#616161',
                        textTransform: 'uppercase',
                        fontSize: '10px',
                        fontWeight: 500,
                        marginLeft: '-5px',
                        marginTop: 4,
                        fontFamily: 'Montserrat'
                      }}
                    >
                      {selectedLangObj.name}
                    </Typography>
                  </div>)
              )}
              >
                {listLanguagesInterview.filter((lang) => lang.name !== selectedLangObj.name) // 🔥 Filtra el seleccionado
                  .map((lang) => (
                    <MenuItem key={lang.name} value={lang}>
                      <img
                        alt={''}
                        src={lang.flag}
                        style={{height: 18, width: 18, marginRight: 10}}
                      />
                      <ListItemText primary={lang.name} sx={{
                        "& .MuiTypography-root": {
                          fontSize: '10px',
                          fontWeight: 500,
                          fontFamily: 'Montserrat'
                        },
                      }}/>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box>

            </Box>
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                border: `1px solid ${lighten(theme.palette.primary.main, 0.2)}`,
                borderRadius: "13px",
                height: "26px",
                padding: "4px 12px 4px 8px",
                color: lighten(theme.palette.primary.main, 0.1),
                fontFamily: "Montserrat",
                fontSize: "10px",
              }}
              onClick={() => setModalQR(true)}
              disabled={!testInterview}
            >
              <PlayArrow
                sx={{color: testInterview ? lighten(theme.palette.primary.main, 0.2) : '#BDBDBD'}}/>
              {t('test_interview')}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            width: "80%",
            height: "100%",
            minHeight: "500px",
            position: "relative",
          }}
        >
          <div style={{width: '87vw', height: '70vh', zIndex: 999}}>
            <ReactFlow
              nodes={nodesFlow}
              edges={edges}
              nodeTypes={nodeTypes}
              fitView
            />
          </div>
        </Box>
      </Box>
      <Messages
        open={showModal}
        handleClose={handleCloseModal}
        showModalGeneric={showGenericModal}
        nodeSelected={nodeSelected}
        setNodeSelected={setNodeSelected}
        survey={survey}
        questions={questions}
        listLanguagesInterview={listLanguagesInterview}
        refrechDataGraph={refrechDataGraph}
      />
      <QRModal
        open={modalQR}
        handleClose={() => setModalQR(false)}
        survey={{id: survey.serviceId, environment: survey.environment}}
      />
    </>
  )
}

export default FlowDesign;
