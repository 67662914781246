import React from 'react';
import {PlayArrow} from "@mui/icons-material";
import {
  Autocomplete, Button, Checkbox,
  FormControl, FormControlLabel, Grid, IconButton,
  Stack, Tooltip, TextField, InputAdornment
} from "@mui/material";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {
  containerSwitchStyle, dividerStyle, MuiGridOthersTab,
  MuiOptionsTypography,
  MuiSwitchOthers, MuiSwitchTypography
} from "../styles/genericModalMessage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import whatsappLogo from 'assets/img/whatsappLogo.png'
import telegramLogo from 'assets/img/telegramLogo.png'
import {
  checkStyle, dangerStyle,
  infoStyle,
  MuiAlertApp,
  MuiAlertMessage,
  MuiAppTitle,
  MuiBoxAppLogo,
  MuiBoxForm,
  MuiBoxTelegram,
  MuiBoxWhatsapp,
  MuiGridContainer,
  MuiGridHeader,
  MuiGridItem,
  MuiIconButton,
  MuiPhoneNumber,
  MuiTextCheckBox,
  MuiTextGenerateToken,
  MuiTitle,
  textInputStyle
} from "../styles/communicationChannels";
import QRModal from "../QRModal";
import useSurveyCommunicationStatus
  from "../../../../../api/hooks/useSurveyCommunicationStatus";
import useSurveyConfig from "../../../../../api/hooks/useSurveyConfig";
import DangerousIcon from '@mui/icons-material/Dangerous';

const CommunicationChannels = ({survey, setSurvey}) => {
  const [enabledWhatsapp, setEnabledWhatsapp] = React.useState(true);
  const [modalQR, setModalQR] = React.useState(false);
  const [enabledTelegram, setEnabledTelegram] = React.useState(true);
  const [twilioNumber, setTwilioNumber] = React.useState("");
  const [messageServiceId, setMessageServiceId] = React.useState("");
  const [tgToken, setTgToken] = React.useState("");
  const [phoneNumbers, setPhoneNumbers] = React.useState([])
  const [keepPhoneNumber, setKeepPhoneNumber] = React.useState(true)
  const [anonymizeVoiceNotes, setAnonymizeVoiceNotes] = React.useState(true)
  const [deepAnonymize, setDeepAnonymize] = React.useState(true)
  const [inputValue, setInputValue] = React.useState('');

  const {theme}= useTheme();
  const { t } = useTranslation();
  const {data: config} = useSurveyConfig({id: survey.serviceId});
  const communicationStatus = useSurveyCommunicationStatus(
    {
      service_id: survey.serviceId,
      environment:survey.environment,
      type:'',
    });

  const testInterview = survey && survey.status==='Running';
  const phoneRegex = /^\+\d{1,3} \d{7,15}$/;

  const handleCopy = async (idField = '') => {
    if (idField === 'apiMessageCallback') {
      try {
        await navigator.clipboard.writeText(`${survey.engineBaseUrl}/message`);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(`${survey.engineBaseUrl}/message_status`);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChange = (event, values) => setPhoneNumbers(values);

  const handleEnterPhoneNumber = (event) => {
    if (event.key === "Enter" && phoneRegex.test(inputValue)) {
      setPhoneNumbers([...phoneNumbers, inputValue]);
      setInputValue("");
    }
  };

  const handleInputChange = (event, newValue) => {
    if (!/^\+\d*$/.test(newValue)) {
      setInputValue("");
    } else {
      setInputValue(newValue);
    }
  };

  const handleGenerateToken = () => alert('Generated Token')

  React.useEffect(() => {
    if(config){
      setKeepPhoneNumber(config.storeRespondentPhoneNumber)
      setAnonymizeVoiceNotes(config.anonymizeVoiceNotes)
      setDeepAnonymize(config.deepAnonymization)
      setTwilioNumber(config.twilioNumber)
      setPhoneNumbers(config.managementPhoneNumbers)
      setMessageServiceId(config.messageServiceId)
      setTgToken(config.telegramAPIToken)
    }
  }, [config])

  React.useEffect(() => {
      setEnabledWhatsapp(communicationStatus[0]?.data?.status === 'failed' ? false : true)
      setEnabledTelegram(communicationStatus[1]?.data?.status === 'failed' ? false : true)
  }, [communicationStatus])

  return (
    <MuiGridContainer container>
      <MuiGridHeader item xs={12}>
        <MuiTitle>Communication Channels</MuiTitle>
        <MuiIconButton
          primaryColor={theme.palette.primary.main}
          onClick={() => setModalQR(true)}
          disabled={!testInterview}
        >
          <PlayArrow sx={{ color: '#00828F'}} />
          {t('test_interview')}
        </MuiIconButton>
      </MuiGridHeader>
      <MuiBoxWhatsapp>
        <div style={{display:'flex'}}>
          <MuiBoxAppLogo
            component="img"
            src={whatsappLogo}
            alt={`img-whatsappLogo`}
          />
          <div>
            <MuiAppTitle>Whatsapp settings</MuiAppTitle>
            <MuiGridOthersTab item xs={12}>
              <MuiOptionsTypography sx={{marginRight:'12px'}}>
                Enabling interactions through WhatsApp
              </MuiOptionsTypography>
              <div style={{ display:'flex' }}>
                <MuiSwitchOthers
                  name='enabledWhatsapp'
                  primaryColor={theme.palette.primary.main}
                  onChange={() => {setEnabledWhatsapp(!enabledWhatsapp)}}
                  checked={enabledWhatsapp}
                />
                <div style={containerSwitchStyle}>
                  <MuiSwitchTypography style={{ color:  '#000000' }}>
                    No
                  </MuiSwitchTypography>
                  <span style={dividerStyle}>/</span>
                  <MuiSwitchTypography
                    style={{ color: theme.palette.primary.main }}
                  >
                    Yes
                  </MuiSwitchTypography>
                </div>
              </div>
            </MuiGridOthersTab>
          </div>
        </div>
        {communicationStatus[0]?.data?.status === 'success' ? (
          <MuiAlertApp severity="success" icon={false}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CheckCircleIcon sx={checkStyle} />
              <MuiAlertMessage variant="body1">
                {`WhatsApp ${communicationStatus[0]?.data?.message}`}
              </MuiAlertMessage>
            </Stack>
          </MuiAlertApp>
        ) : (
          <MuiAlertApp severity="error" icon={false}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <DangerousIcon sx={dangerStyle} />
              <MuiAlertMessage variant="body1" sx={{color:'red !important'}}>
                {`WhatsApp service failed`}
              </MuiAlertMessage>
            </Stack>
          </MuiAlertApp>
        )}
      </MuiBoxWhatsapp>
      <MuiBoxForm enabledAppForm={enabledWhatsapp}>
        <Grid item xs={12}>
          <TextField
            label="WhatsApp API message callback:"
            variant="outlined"
            fullWidth
            value={`${survey.engineBaseUrl}/message`}
            readOnly='true'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleCopy('apiMessageCallback')} edge="end">
                    <ContentCopyRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: textInputStyle,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{marginTop:'18px'}}>
          <TextField
            label="WhatsApp API delivery status callback:"
            variant="outlined"
            fullWidth
            value={`${survey.engineBaseUrl}/message_status`}
            readOnly='true'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopyRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: textInputStyle,
            }}
          />
        </Grid>
        <MuiGridItem item columnGap={'18px'}>
          <TextField
            label="Twilio Number"
            variant="outlined"
            fullWidth
            value={twilioNumber}
          />
          <TextField
            label="Message Service ID"
            variant="outlined"
            fullWidth
            value={messageServiceId}
            // onChange={(e) => setText(e.target.value)}
          />
        </MuiGridItem>
        <Grid item sx={{ marginTop:'18px'}}>
          <FormControl fullWidth variant="outlined" >
            <Autocomplete
              id="selected-phoneNumber"
              multiple
              freeSolo
              options={phoneNumbers}
              value={phoneNumbers}
              onChange={handleChange}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) => phoneRegex.test(option) )
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <MuiPhoneNumber
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Management Numbers"
                  placeholder="+52 1234567890"
                  onKeyDown={handleEnterPhoneNumber}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item sx={{ marginTop:'18px'}}>
          <MuiAppTitle>Privacy options</MuiAppTitle>
          <div style={{display:"flex"}}>
            <FormControlLabel
              label={
                <MuiTextCheckBox>
                  Keep Respondent Phone Number
                  <Tooltip title="Info of the field here.">
                    <InfoOutlinedIcon sx={infoStyle} />
                  </Tooltip>
                </MuiTextCheckBox>
              }
              control={
                <Checkbox
                  checked={keepPhoneNumber}
                  onChange={() => setKeepPhoneNumber(!keepPhoneNumber)}
                />
              }
            />
            <FormControlLabel
              label={
                <MuiTextCheckBox>Anonymize Voice Notes</MuiTextCheckBox>
              }
              control={
                <Checkbox
                  checked={anonymizeVoiceNotes}
                  onChange={() => setAnonymizeVoiceNotes(!anonymizeVoiceNotes)}
                />
              }
            />
            <FormControlLabel
              label={
                <MuiTextCheckBox>
                  Deep anonymize
                  <Tooltip title="Info of the field here.">
                    <InfoOutlinedIcon sx={infoStyle} />
                  </Tooltip>
                </MuiTextCheckBox>
              }
              control={
                <Checkbox
                  checked={deepAnonymize}
                  onChange={() => setDeepAnonymize(!deepAnonymize)}
                />
              }
            />
          </div>
        </Grid>
      </MuiBoxForm>
      <MuiBoxTelegram>
        <div style={{display:'flex'}}>
          <MuiBoxAppLogo
            component="img"
            src={telegramLogo}
            alt={`img-whatsappLogo`}
          />
          <div>
            <MuiAppTitle>Telegram settings</MuiAppTitle>
            <MuiGridOthersTab item xs={12}>
              <MuiOptionsTypography sx={{marginRight:'12px'}}>
                Enabling interactions via Telegram
              </MuiOptionsTypography>
              <div style={{ display:'flex' }}>
                <MuiSwitchOthers
                  name='enabledTelegram'
                  primaryColor={theme.palette.primary.main}
                  onChange={() => {setEnabledTelegram(!enabledTelegram)}}
                  checked={enabledTelegram}
                />
                <div style={containerSwitchStyle}>
                  <MuiSwitchTypography style={{ color:  '#000000' }}>
                    No
                  </MuiSwitchTypography>
                  <span style={dividerStyle}>/</span>
                  <MuiSwitchTypography
                    style={{ color: theme.palette.primary.main }}
                  >
                    Yes
                  </MuiSwitchTypography>
                </div>
              </div>
            </MuiGridOthersTab>
          </div>
        </div>
        {communicationStatus[1]?.data?.status === 'success' ? (
          <MuiAlertApp severity="success" icon={false}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CheckCircleIcon sx={checkStyle} />
              <MuiAlertMessage variant="body1">
                {`Telegram ${communicationStatus[1]?.data?.message}`}
              </MuiAlertMessage>
            </Stack>
          </MuiAlertApp>
        ) : (
          <MuiAlertApp severity="error" icon={false}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <DangerousIcon sx={dangerStyle} />
              <MuiAlertMessage variant="body1" sx={{color:'red !important'}}>
                {`Telegram service failed`}
              </MuiAlertMessage>
            </Stack>
          </MuiAlertApp>
        )}
      </MuiBoxTelegram>
      <MuiBoxForm enabledAppForm={enabledTelegram}>
        <Grid item xs={12}>
          <TextField
            label="Chatbot token"
            variant="outlined"
            fullWidth
            value={tgToken}
          />
          <Button variant="text" sx={{marginTop:'18px'}} onClick={handleGenerateToken}>
            <MuiTextGenerateToken>
              Click here to generate the token
            </MuiTextGenerateToken>
          </Button>
        </Grid>
      </MuiBoxForm>
      <QRModal
        open={modalQR}
        handleClose={() => setModalQR(false)}
        survey={{id:survey.serviceId}}
      />
    </MuiGridContainer>
  );
};

export default CommunicationChannels;
