import {
  useState,
  useMemo,
} from 'react'
import {
  Grid2 as Grid,
  Box,
  IconButton,
  lighten,
} from '@mui/material';
import {
  MuiButton,
  MuiSubTitle,
} from 'components/views/organizations/surveys/styles/translations';
import {
  MuiTitleTypography,
} from 'components/views/organizations/surveys/styles/generalInformation';
import {
  Translate as TranslateIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import { useTranslation } from 'components/providers/TranslationProvider';
import { useTheme } from "components/providers/CustomThemeProvider";
import SurveyTranslations from "../SurveyTranslations";
import TranslateTextPopover from "../TranslateTextPopover";
import useQuestions from 'api/hooks/useQuestions';
import useSurveyTranslations from 'api/hooks/useSurveyTranslations';
import useSurveyGenerateQuestionTraslation from "api/hooks/useSurveyGenerateQuestionTraslation";
import useSurveyGenerateTranslationMessage from "api/hooks/useSurveyGenerateTranslationMessage";
import QRModal from "components/views/organizations/surveys/QRModal.js";
import spanishFlag from "assets/img/spanish.png";
import englishFlag from "assets/img/english.png";
import frenchFlag from "assets/img/french.png";
import brazilFlag from "assets/img/portuguese-brazil.png";
import arabicFlag from "assets/img/arabic.png";
import {useLoading} from 'components/providers/LoadingProvider.js';

const Translations = ({survey, setSurvey}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [translationData, setTranslationData] = useState({});
  const testInterview = survey && survey.status==='Running';
  const [modalQR, setModalQR] = useState(false);
  const [internationalizationData, setInternationalizationData] = useState({});

  const {
    data: questions,
  } = useQuestions({
    serviceId: survey.serviceId,
  });

  const {data: surveyTranslations} = useSurveyTranslations({
    serviceId: survey.serviceId,
  });

  const questionMessages = useMemo(() => {
    if (questions) {
      return questions.map(q => ({
        id: q.id,
        shortLabel: q.shortLabel,
        internationalization: q.internationalization,
      }));
    }
    return [];
  }, [questions]);

  const translationMessages = useMemo(() => {
    if (surveyTranslations) {
      return surveyTranslations.map(t => ({
        id: t._id,
        shortLabel: t.shortLabel,
        internationalization: t.internationalizations,
        key: t.key,
      }));
    }
    return [];
  }, [surveyTranslations]);

  const messageKeys = useMemo(() => (
    [
      {
        code: 'title',
        label: 'Title',
        remote: 'TITLE',
      },
      {
        code: 'text',
        label: 'Main text',
        remote: 'MAIN_TEXT',
      },
      {
        code: 'footerText',
        label: 'Footer',
        remote: 'FOOTER_TEXT',
      },
      {
        code: 'allowedAnswers',
        label: 'Options',
        remote: 'OPTIONS',
      },
    ]
  ), []);

  const {
    mutateAsync: generateQuestionTraslation,
    isLoading: questionTraslationIsLoading,
  } = useSurveyGenerateQuestionTraslation();

  const {
    mutateAsync: generateTranslationMessage,
    isLoading:  translationMessageIsLoading,
  } = useSurveyGenerateTranslationMessage();

  const {
      setIsLoading,
      setTitle,
      setProgress,
      setProgressMessage,
      setShowProgressBar,
      setIsClassificationLoading
    } = useLoading();

  const predefinedLanguages = [
    { name: "english", code: "en", flag: englishFlag },
    { name: "spanish", code: "es", flag: spanishFlag },
    { name: "french", code: "fr", flag: frenchFlag },
    { name: "portuguese", code: "pt", flag: brazilFlag },
    { name: "arabic", code: "ar", flag: arabicFlag },
  ];

  const languagesToExclude = useMemo(() => {
      return predefinedLanguages
        .map(lang => lang.name)
        .filter(name =>
          name !== survey.defaultLanguage &&
          !survey.additionalLanguages.some(lang => lang.name === name)
        );
    }, [survey.additionalLanguages, survey.defaultLanguage]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGenerateQuestionTranslation = async (translationData, selectedQuestionId) => {
    await generateQuestionTraslation({
      serviceId: survey.serviceId,
      environment: survey.environment,
      sourceLanguage: translationData.from,
      targetLanguages: translationData.to,
      questionSections: translationData.selectedQuestionsSections.map((s) => s.remote),
      questionId: selectedQuestionId,
    });
  };

  const handleGenerateTranslationMessage = async (translationData, selectedQuestionId) => {
    await generateTranslationMessage({
      serviceId: survey.serviceId,
      environment: survey.environment,
      sourceLanguage: translationData.from,
      targetLanguages: translationData.to,
      questionSections: translationData.selectedQuestionsSections.map((s) => s.remote),
      translationKey: selectedQuestionId,
    });
  };

  const translationMessageState = {
    isLoading: translationMessageIsLoading,
  };

  const questionTranslationState = {
    isLoading: questionTraslationIsLoading
  };

  const handleTranslate = async () => {
    setIsLoading(true);
    setProgress(0);
    setTitle(t('translating_selected_text'));
    setShowProgressBar(true);
    setIsClassificationLoading(true);

    const questions = translationData?.selectedQuestions || [];
    const genericMessages = translationData?.selectedGenericMessages || [];
    const totalMessagesCount = questions.length + genericMessages.length;

    let completedCount = 0;

    const updateProgress = () => {
      setProgress(Math.round((completedCount / totalMessagesCount) * 100));
    };

    if (totalMessagesCount === 0) {
      setProgress(100);
      setIsLoading(false);
      return;
    }

    const processItems = async (items, type, total) => {
      for (const [index, item] of items.entries()) {
        if (type === 'question') {
          setProgressMessage(`${t('translating_selected_questions')} ${index + 1}/${total}`);
          await handleGenerateQuestionTranslation(translationData, item.id);
        } else if (type === 'generic') {
          setProgressMessage(`${t('translating_selected_generic_messages')} ${index + 1}/${total}`);
          await handleGenerateTranslationMessage(translationData, item.key);
        }
        completedCount++;
        updateProgress();
        // Pause briefly between translations
        await new Promise(resolve => setTimeout(resolve, 200));
      }
    };

    await processItems(questions, 'question', questions.length);
    await processItems(genericMessages, 'generic', genericMessages.length);

    setProgress(100);
    setIsLoading(false);
    setProgress(0);
    setTitle('');
    setShowProgressBar(false);
    setIsClassificationLoading(false);
    handleClose();
  };


  const onInternationalizationDataChange = ( internationalizationData ) => {
    setInternationalizationData(internationalizationData);
  }

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: '35px' }}>
          <MuiTitleTypography variant="h6">
            {t('survey_translations')}
          </MuiTitleTypography>
          <Box sx={{ display: "flex" }}>
            <MuiButton
              startIcon={<TranslateIcon />}
              variant="outlined"
              sx={{ marginRight: '12px', padding: '4px 12px 4px 8px', height: '26px' }}
              onClick={handleOpen}
            >
              {t('translate_full_interview')}
            </MuiButton>
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                border: `1px solid ${lighten(theme.palette.primary.main, 0.2)}`,
                borderRadius: "13px",
                height: "26px",
                padding: "4px 12px 4px 8px",
                color: lighten(theme.palette.primary.main, 0.1),
                fontFamily: "Montserrat",
                fontSize: "10px",
              }}
              onClick={() => setModalQR(true)}
              disabled={!testInterview}
            >
              <PlayArrowIcon sx={{ color: testInterview ? lighten(theme.palette.primary.main, 0.2) : '#BDBDBD'}} />
              {t('test_interview')}
            </IconButton>
            <TranslateTextPopover
              anchorEl={anchorEl}
              onClose={handleClose}
              dataToTranslate={questionMessages}
              dataKeys={messageKeys}
              contentType="multipleObjects"
              setTranslationData={setTranslationData}
              onTranslate={handleTranslate}
              presetFromLanguage = {internationalizationData?.language}
              presetExcludeToLanguage = { languagesToExclude }
              genericMessagesToTranslate = { translationMessages }
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MuiSubTitle sx={{ marginTop: '30px', marginBottom: '18px' }}>
          {t('main_messages')}
        </MuiSubTitle>
      </Grid>
      <Grid item xs={12}>
        <SurveyTranslations
          survey={survey}
          dataKeys={messageKeys}
          setSurvey={setSurvey}
          messages={questionMessages}
          onGenerateTranslation={handleGenerateQuestionTranslation}
          messageType="question"
          translationState = {questionTranslationState}
          onInternationalizationDataChange={onInternationalizationDataChange}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiSubTitle sx={{ marginTop: '30px', marginBottom: '18px' }}>
          {t('auxiliary_messages')}
        </MuiSubTitle>
      </Grid>
      <Grid item xs={12}>
        <SurveyTranslations
          survey={survey}
          setSurvey={setSurvey}
          messages={translationMessages}
          onGenerateTranslation={handleGenerateTranslationMessage}
          messageType="translation"
          translationState = {translationMessageState}
        />
      </Grid>
      <QRModal
        open={modalQR}
        handleClose={() => setModalQR(false)} survey={{id:survey.serviceId}}
      />
    </>
  );
};

export default Translations;
