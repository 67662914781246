import React from 'react';
import {Navigate} from 'react-router-dom';

import Main from 'components/views/Main';
import NotFound from 'components/views/NotFound';
import Error from 'components/views/Error';
import Login from 'components/views/login/Login';
import Organizations from 'components/views/organizations/Organizations';
import Users from 'components/views/organizations/users/Users';
import Surveys from 'components/views/organizations/surveys/Surveys';
import SurveyContainer from 'components/views/organizations/surveys/Container';
import AnalyticsDashboard
  from "./components/views/organizations/surveys/AnalyticsDashboard";
import Scenarios from 'components/views/organizations/scenarios/Scenarios';
import Datasets from 'components/views/organizations/datasets/Datasets';
import Organization
  from 'components/views/organizations/organization/Organization';
import ForgotPassword from 'components/views/login/ForgotPassword';
import ResetPassword from 'components/views/login/ResetPassword';
import LoginLayout from 'components/views/login/LoginLayout';
import Workspace from 'components/views/organizations/workspace/Workspace';
import Translations from 'components/views/trasnlations/Translations';
import Specification
  from 'components/views/organizations/scenarios/specification/Specification';
import AnalysisView
  from './components/views/organizations/scenarios/analysis/AnalysisView';
import ViewReportPDF
  from "./components/views/organizations/scenarios/exporting/ViewReportPDF";

export const secureRoutes = (type, orgId, orgsUserLevel) => {

  switch (type) {
    case 'level_0':
      return level0User();
    case 'level_1':
      return level1User(orgId, orgsUserLevel);
    case 'level_2':
      return levelUser(orgId, orgsUserLevel);
    case 'level_3':
      return levelUser(orgId, orgsUserLevel);
    case 'admin':
      return admin();
    case 'super_admin':
      return superAdmin;
    default:
      break;
  }
};

const level0User = () => {
  return [
    {
      path: '/orgs/:idOrg/scenarios/:id/analysis/report',
      element: <AnalysisView/>,
    },
  ];
};

const level1User = (orgId, orgsUserLevel) => {
  if (orgsUserLevel.length === 0) {
    return [
      {
        path: `/orgs/${orgId}`,
        element: <Organization/>,
        exact: true,
        children: [...scenariosDatasets],
      },
      {path: '*', element: <Navigate to={`/orgs/${orgId}/scenarios`}/>},
      {
        path: `/orgs/${orgId}/scenarios/:id/analysis`,
        element: <AnalysisView/>,
      },
    ]
  }
  return [
    ...commonRouters,
    {
      path: '/orgs/:id',
      element: <Organization/>,
      children: [
        ...scenariosDatasets,
      ],
    },
    {
      path: '/orgs/:idOrg/scenarios/:id/analysis',
      element: <AnalysisView/>,
    },
  ];
};

const levelUser = (orgId, orgsUserLevel) => {
  if (orgsUserLevel.length === 0) {
    return [
      {
        path: `/orgs/${orgId}`,
        element: <Organization/>,
        exact: true,
        children: [...scenariosDatasets],
      },
      {path: '*', element: <Navigate to={`/orgs/${orgId}/scenarios`}/>},
      {
        path: `/orgs/${orgId}/scenarios/:id/analysis`,
        element: <AnalysisView/>,
      },
    ]
  }
  return [
    ...commonRouters,
    {
      path: '/',
      element: <Navigate to={`/orgs`}/>,
    },
    {
      path: '/orgs/:id',
      element: <Organization/>,
      children: [
        ...scenariosDatasets,
      ],
    },
    {
      path: '/orgs/:idOrg/scenarios/:id/analysis',
      element: <AnalysisView/>,
    },
  ];
}
const admin = () => {
  return [
    ...commonRouters,
    {
      path: '/',
      element: <Navigate to={`/orgs`}/>,
    },
    {
      path: '/orgs/:id',
      element: <Organization/>,
      children: [
        ...scenariosDatasets,
        {path: 'users', element: <Users/>},
        {path: 'surveys', element: <Surveys/>},
        {
          path: 'surveys/:idSurvey/analytics',
          element: <AnalyticsDashboard/>
        }
      ],
    },
    {
      path: '/orgs/:idOrg/scenarios/:id/analysis',
      element: <AnalysisView/>,
    },
    {
      path: '/orgs/workspace',
      element: <Workspace/>,
      children: [...scenariosDatasets],
    },
    {
      path: '*',
      element: <Organizations/>,
    },
  ];
};

const commonRouters = [
  {path: '/error', element: <Error/>},
  {path: '/orgs', element: <Organizations/>},
  {path: '404', element: <NotFound/>},
  {path: '*', element: <Navigate to='/404'/>},
  {
    path: '/orgs/workspace/scenarios/:scenarioId/analysis',
    element: <AnalysisView/>,
  },
  {
    path: '/orgs/:idOrg/scenarios/:id/analysis/report',
    element: <ViewReportPDF/>,
  },
];

const scenariosDatasets = [
  {path: 'datasets', element: <Datasets/>},
  {
    path: 'scenarios',
    element: <Scenarios/>,
  },
  {
    path: 'scenarios/:idScenarios/specification',
    element: <Specification/>
  },

];

const superAdmin = [
  {path: '/', element: <Main/>},
  {
    path: '/orgs/:id',
    element: <Organization/>,
    exact: true,
    children: [...scenariosDatasets, {
      path: 'users',
      element: <Users/>
    }, {
      path: 'surveys',
      element: <Surveys/>
    }, {
      path: 'surveys/:idSurvey/specification',
      element: <SurveyContainer/>
    },
      {
        path: 'scenarios/:idScenarios/specification',
        element: <Specification/>
      },
    ],
  },
  {
    path: '/orgs/:idOrg/surveys/:idSurvey/analytics',
    element: <AnalyticsDashboard/>
  },
  {
    path: '/orgs/workspace',
    element: <Workspace/>,
    children: [...scenariosDatasets, {
      path: 'scenarios/:idScenarios/specification',
      element: <Specification/>
    }],
  },
  {
    path: '/orgs/:idOrg/scenarios/:id/analysis',
    element: <AnalysisView/>,
  },
  {
    path: '/orgs/:idOrg/scenarios/:id/analysis/report',
    element: <ViewReportPDF/>,
  },
  {path: '/translations', element: <Translations/>},
  ...commonRouters,
];

export const routes = [
  {
    path: '*',
    element: <LoginLayout/>,
    children: [
      {path: '*', element: <Login/>},
      {path: 'forgot-password', element: <ForgotPassword/>},
      {path: 'reset', element: <ResetPassword/>},
    ],
  },
];
